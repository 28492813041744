import React, { Component } from 'react';
import { observer } from 'mobx-react';

import transitionStore from '../stores/TransitionStore';
import helpers from '../helpers';

const Transition = observer(
class Transition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transit: false
    }
  }

  componentDidMount = () => {
    if(this.props.on === 'inview') {
      window.addEventListener('scroll', this.checkInView, false);
      this.checkInView();
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.checkInView, false);
  }

  checkInView = () => {
    const viewportRect = helpers.getRectForViewport();
    const thisRect = helpers.getRectForNode(this);
    const by = this.props.by || 50;

    if(thisRect.top > 0 && thisRect.top < (viewportRect.height - by)) {
      setTimeout(() => {
        this.setState({transit: true});
        transitionStore.delayOffset = transitionStore.delayOffset - 150;
      }, transitionStore.delayOffset);

      transitionStore.delayOffset = transitionStore.delayOffset + 150;

      window.removeEventListener('scroll', this.checkInView, false);
    }
  }

  render() {
    let classes = '';

    if(typeof this.props.type !== 'undefined') {
      classes = classes+' transition--'+this.props.type;
    }

    if(this.state.transit) {
      classes = classes+' transit';
    }

    return (
      <div className={'transition '+classes}>{this.props.children}</div>
    )
  }
}
)

export default Transition;