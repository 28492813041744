import React, { Component } from 'react';
import { observer } from 'mobx-react';

import helpers from '../helpers';
import gameStore from '../stores/GameStore';

import Player from './Player';
import PlayerWeapon from './PlayerWeapon';
import Monster from './Monster';
import Tile from './Tile';

import keysImg from '../assets/img/keys.svg';
import mouseImg from '../assets/img/mouse.svg';

// TODO
// Add button to start game... I think state.playing to true? then I load in game, on mount I can just do init sequence there
// Starting sequence
// Winning sequence... need to show text, then button to "Close game", which basically fades out the game and adds the button at the top again
// Clean columns
// Attribute zapsplat for sounds I think, and probably that other guy for artwork
// Show controls

// COULDDO
// Fix last trap...
// Alternate sounds
// Add in coins
// Update weapon angle after player moves
// Scroll down when opening a door
// Shift functions out into the components... eg, hitting, and moving, and triggering... also helper functions
// Add doors
// Different weapons
// Different heroes
// Clear dead enemies from state... may need to use proper keys
// Only clone first child of state, not whole state
// Trap groups... that way I can make a corridor of traps with a tunnel in between the timings
// Have a config file for the consts

// Store...
// I can pass parts as props, but then that's a lot...
// I can import store into every part of the app, but could be difficult to test later and what about common functions like checkPlayerGetsHit? Maybe I can make them more plain and share between helpers

const Game = observer(
class Game extends Component {
  constructor(props) {
    super(props);
    this.store = gameStore;
    this.music = new Audio('static/sound/background_track.mp3');
    this.music.volume = 0.3;
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.refreshRect, false);
    window.addEventListener('scroll', this.refreshRect, false);

    this.refreshRect();

    helpers.scrollToOffset(helpers.getOffsetForPosition(this.store.player.position));

    this.music.addEventListener('ended', function() {
      this.currentTime = 0;
      this.play();
    }, false);
    this.music.play();
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.refreshRect, false);
    window.removeEventListener('scroll', this.refreshRect, false);
    this.music.pause();
  }

  refreshRect = () => {
    this.store.rect = helpers.getRectForNode(this);
  }

  replay = () => {
    this.store.reset();
    this.store.started = true;
    this.store.starting = true;

    helpers.scrollToOffset(helpers.getOffsetForPosition(this.store.player.position));
  }

  close = () => {
    this.store.reset();
  }
  
  render() {
    let classes = 'game';

    if(gameStore.completed) {
      classes = classes + ' game--completed';
    }

    if(gameStore.completed) {
      this.music.pause();
    }

    return (
      <div className={classes}>
        <div className="game__credits">
          <ul>
            <li>Sounds provided by <a href="https://www.zapsplat.com/" target="_blank" rel="noopener noreferrer">Zapsplat</a></li>
            <li>Artwork provided by <a href="https://0x72.itch.io/dungeontileset-ii" target="_blank" rel="noopener noreferrer">Robin</a></li>
            <li className="game__close" onClick={this.close}><span aria-label="Close game">x</span></li>
          </ul>
        </div>
        <div className="game__instructions">
          <div className="game__instruction game__instruction--keys">
            <img src={keysImg} height="100" alt="keyboard arrow keys icon" />
            Use the arrow keys to move
          </div>
          <div className="game__instruction game__instruction--keys">
            <img src={mouseImg} height="100" alt="mouse icon" />
            Attack with the left mouse button
          </div>
        </div>
        <div className="game__tiles">
          {gameStore.tiles.map((tile, index) => {
            return <Tile key={index+'|'+tile.sprite} index={index} />;
          })}
        </div>
        {gameStore.completed ?
          <div className="game__ending">
            <h3>You win!</h3>
            <p>You saved the princess from her confines! Unfortunately she died from suffocation whilst in the chest.</p>
            <div className="game__decision">
              <button className="button button--dark button--retro game__action game__action--replay" onClick={this.replay}>
                <span className="button__label button__label--default">Again</span>
              </button>
              <button className="button button--dark game__action game__action--close" onClick={this.close}>
                <span className="button__label button__label--default">Close</span>
              </button>
            </div>
          </div>
          : 
          <div>
            <div className="game__entities">
              {gameStore.monsters.map((monster, index) => {
                return <Monster key={index+'|'+monster.sprite} index={index} />
              })} 
              <Player >
                <PlayerWeapon />
              </Player>
            </div>
          </div>
        }
      </div>
    );
  }
}
)

export default Game;