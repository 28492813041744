import React, { Component } from 'react';
import { observer } from 'mobx-react';
import helpers from '../helpers';
import config from '../config';
import gameStore from '../stores/GameStore';

let triggerInterval;

const Tile = observer(
class Tile extends Component {
  constructor(props) {
    super(props);
    this.store = gameStore.tiles[props.index];

    if(this.store.sprite === 'floor_r') {
      this.store.sprite = this.store.sprite.replace('_r', '_'+helpers.getRandomInt(1, 7));
    }
  }

  componentDidMount = () => {
    if(typeof this.store.triggered !== 'undefined' && config.traps.includes(this.store.sprite)) {
      triggerInterval = setInterval(() => {
        this.trigger();
      }, config.trapsTriggerIntervalLength);
    }
  }

  componentWillUnmount = () => {
    clearInterval(triggerInterval);
  }

  trigger = () => {
    if(!gameStore.completed) {
      this.store.triggered = !this.store.triggered;

      // Hit player if triggered in player's spot...
      if(this.store.triggered && helpers.objectsAreEqual(gameStore.player.position, this.store.position)) {
        gameStore.hitPlayer();
      }
    } else {
      clearInterval(triggerInterval);
    }
  }

  render() {
    let style = helpers.getOffsetForPosition(this.store.position, false);
    let classes = '';
    let text = '';
    let reward;

    if(this.store.triggered) {
      classes = classes + ' triggered';
    }

    if(config.debug) {
      text = this.store.position.x+','+this.store.position.y;
    }

    style.zIndex = this.store.position.y;
    if(this.store.sprite.includes('floor') || this.store.sprite.includes('wall_side') || this.store.sprite.includes('column_base') || this.store.sprite.includes('void') || this.store.sprite.includes('edge')) {
      style.zIndex = style.zIndex - 1;
    }
    if(this.store.sprite.includes('chest_') || this.store.sprite.includes('column_top') || this.store.sprite.includes('wall_top')) {
      style.zIndex = style.zIndex + 1;
    }

    if(this.store.sprite === 'chest_open' && typeof this.store.reward !== 'undefined' && !this.store.claimed) {
      reward = <div className={'game__reward sprite--'+this.store.reward}></div>
    }

    return (
      <div className={'game__tile sprite--'+this.store.sprite+classes} style={style}>{reward}{text}</div>
    )
  }
}
)

export default Tile;