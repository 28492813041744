import React, { Component } from 'react';
import { observer } from 'mobx-react';
import helpers from '../helpers';
import config from '../config';
import gameStore from '../stores/GameStore';

let movableTimeout;
let movingTimeout;
let triggerInterval;

const Player = observer(
class Player extends Component {
  constructor(props) {
    super(props);
    this.store = gameStore.player;
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeydown, false);

    triggerInterval = setInterval(() => {
      this.checkTrapTrigger();
    }, config.trapsTriggerIntervalLength);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeydown, false);
    clearTimeout(triggerInterval);
  }

  checkTrapTrigger = () => {
    // TODO - dirty... basically just detecting if trap is triggered near player... but the timeout is not clean
    const traps = gameStore.getTilesAroundPosition(this.store.position, 'floor_spikes_anim', 2);
    if(traps.length > 0) {
      const sound = new Audio('static/sound/trap_trigger_1.mp3');
      sound.play();
    }
  }

  handleKeydown = (event) => {
    if(config.controls.includes(event.keyCode) && this.store.movable) {
      switch(event.keyCode) {
        case 38:
        case 87:
          this.move('up');
          break;
        case 40:
        case 83:
          this.move('down');
          break;
        case 37:
        case 65:
          this.move('left');
          break;
        case 39:
        case 68:
          this.move('right');
          break;
        default:
          break;
      }
      event.preventDefault();
    }
  }

  move = (direction) => {
    if(this.store.movable) {
      const position = helpers.clone(this.store.position);

      switch(direction) {
        case 'up':
          position.y = position.y - 1;
          break;
        case 'down':
          position.y = position.y + 1;
          break;
        case 'left':
          position.x = position.x - 1;
          break;
        case 'right':
          position.x = position.x + 1;
          break;
        default:
          break;
      }

      if(!this.isBlocked(position)) {
        this.store.position = position;
        this.store.movable = false;
        this.store.moving = true;

        // Scroll to player...
        if(direction === 'up' || direction === 'down') {
          helpers.scrollToOffset(helpers.getOffsetForPosition(position));
        }

        // Check if player gets hit by moving to position...
        if(this.isHitAtPosition(position)) {
          gameStore.hitPlayer();
        }
 
        const sound = new Audio('static/sound/step_2.mp3');
        sound.play();

        // Look for door around player to trigger...
        const doors = gameStore.getTilesAroundPosition(position, 'doors_small_leaf_closed', 2);
        for(let door of doors) {
          if(door.openedBy === 'proximity') {
            door.sprite = 'doors_small_leaf_open';

            const sound = new Audio('static/sound/open_door.mp3');
            sound.play();
          }
        }

        // Look for chest around player to trigger...
        const chests = gameStore.getTilesAroundPosition(position, 'chest_closed', 1);
        if(chests.length > 0) {
          for(let chest of chests) {
            if(!chest.claimed) {
              chest.sprite = 'chest_open';
              this.store.moving = false;
              this.store.claiming = true;

              if(chest.reward !== 'princess') {
                setTimeout(() => {
                  if(chest.reward === 'ui_heart_full') {
                    this.store.maxHealth = this.store.maxHealth + 1;
                    this.store.health = this.store.health + 1;
                  } else {
                    this.store.weapon.sprite = chest.reward;

                    if(this.store.weapon.sprite === 'weapon_golden_sword') {
                      this.store.weapon.damage = 2;
                    }
                  }

                  this.store.movable = true;
                  this.store.claiming = false;
                  chest.claimed = true;
                }, 2750);

                const sound = new Audio('static/sound/open_chest.mp3');
                sound.play();
              } else {
                const sound = new Audio('static/sound/complete.mp3');
                sound.play();

                gameStore.completed = true;
              }
            }
          }
        }

        if(this.store.health > 0 && !this.store.claiming) {
          // Allow character to be movable
          clearTimeout(movableTimeout);
          movableTimeout = setTimeout(() => {
            this.store.movable = true;
          }, 50);

          // Signal character has stopped moving
          clearTimeout(movingTimeout);
          movingTimeout = setTimeout(() => {
            this.store.moving = false;
          }, 500);
        }
      }
    }
  }

  isHitAtPosition = (position) => {
    // If monsters or triggered traps (hazards) are in same position after player has moved, then hit them
    const monsterPositions = gameStore.monsters.reduce((accumulator, monster) => {
      if(monster.health > 0) {
        accumulator.push(monster.position);
      }
      return accumulator;
    }, []);
    const trapPositions = gameStore.tiles.reduce((accumulator, tile) => {
      if(typeof tile.triggered !== 'undefined' && tile.triggered && config.traps.includes(tile.sprite)) {
        accumulator.push(tile.position);
      }
      return accumulator;
    }, []);
    const hazardPositions = monsterPositions.concat(trapPositions);

    for(const hazardPosition of hazardPositions) {
      if(helpers.objectsAreEqual(position, hazardPosition)) {
        gameStore.hitPlayer();
      }
    }
  }

  isBlocked = (position) => {
    const tiles = gameStore.getTilesByPosition(position);
    const blockers = config.blockers;

    if(tiles.length > 0) {
      for(const tile of tiles) {
        if(typeof tile.sprite !== 'undefined') {
          // If the tile sprite has text in from the blockers array, then it's blocked
          for(const blocker of blockers) {
            if(tile.sprite.includes(blocker)) {
              return true;
            }
          }
        }
      }
    } else {
      return true;
    }

    return false;
  }

  render() {
    let style = helpers.getOffsetForPosition(this.store.position);
    // const weaponAngle = helpers.getAngleBetweenOffsets(playerOffset, this.store.mouse.offset);
    // const playerMirrored = (weaponAngle > 180 && weaponAngle < 360);
    let classes = 'game__entity game__player';

    // Determine is hit...
    if(this.store.hit) {
      classes = classes + ' hit';
    }

    // Determine is dying... same as Monster... this could be a good option for HOC with entity perhaps
    if(this.store.health <= 0) {
      classes = classes + ' dying';
    }

    // Determine which sprite to use...
    if(this.store.hit) {
      classes = classes + ' sprite--knight_m_jump';
    } else if(this.store.moving) {
      classes = classes + ' sprite--knight_m_run_anim';
    } else {
      classes = classes + ' sprite--knight_m_idle_anim';
    }

    // Mirror character, and make weapon angle opposite
    if(this.store.mirrored) {
      classes = classes + ' mirrored';
    }
    
    style.zIndex = this.store.position.y;

    let hearts = [];
    for(let i = 1; i < this.store.health; i++) {
      hearts.push(<div className="game__heart sprite--ui_heart_small_full"></div>);
    }

    return (
      <div className={classes} style={style}>
        <div className="game__hearts">{hearts}</div>
        {this.props.children}
      </div>
    )
  }
}
);

export default Player;