export default {
  rect: {},
  starting: false,
  started: false,
  completed: false,
  mouse: {
    offset: {top: 0, left: 0}
  },
  player: {
    position: {x: 1, y: 3},
    mirrored: false,
    movable: true,
    moving: false,
    claiming: false,
    hit: false,
    health: 1,
    maxHealth: 1,
    respawns: 0,
    weapon: {
      rect: {},
      angle: 0,
      hitting: false,
      sprite: 'weapon_regular_sword',
      damage: 1
    }
  },
  monsters: [
    { 
      rect: {}, 
      position: {x: 10, y: 8}, 
      path: [
        {x: 10, y: 7},
        {x: 10, y: 6},
        {x: 10, y: 7},
        {x: 10, y: 8}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 13, y: 7}, 
      path: [
        {x: 13, y: 8},
        {x: 12, y: 8},
        {x: 13, y: 8},
        {x: 13, y: 7}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 14, y: 8}, 
      path: [
        {x: 14, y: 7},
        {x: 13, y: 7},
        {x: 14, y: 7},
        {x: 14, y: 8}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 11, y: 9}, 
      path: [
        {x: 11, y: 10},
        {x: 10, y: 10},
        {x: 10, y: 9},
        {x: 11, y: 9}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 9, y: 10}, 
      path: [
        {x: 9, y: 11},
        {x: 9, y: 12},
        {x: 10, y: 12},
        {x: 9, y: 12},
        {x: 9, y: 11},
        {x: 9, y: 10}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 10, y: 11}, 
      path: [
        {x: 11, y: 11},
        {x: 10, y: 11}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 13, y: 12}, 
      path: [
        {x: 13, y: 13},
        {x: 13, y: 12}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 16, y: 9}, 
      path: [
        {x: 15, y: 9},
        {x: 16, y: 9}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 14, y: 11}, 
      path: [
        {x: 14, y: 10},
        {x: 14, y: 11},
        {x: 14, y: 12},
        {x: 14, y: 11}
      ],
      mirrored: false,
      sprite: 'skelet_idle_anim', 
      hit: false,
      health: 1
    },
    { 
      rect: {}, 
      position: {x: 12, y: 13},
      mirrored: false,
      sprite: 'necromancer_idle_anim', 
      hit: false,
      health: 2
    },
    { 
      rect: {}, 
      position: {x: 11, y: 27},
      path: [
        {x: 11, y: 27},
        {x: 10, y: 27},
        {x: 10, y: 26},
        {x: 10, y: 27}
      ],
      mirrored: false,
      sprite: 'zombie_idle_anim', 
      hit: false,
      health: 3
    },
    { 
      rect: {}, 
      position: {x: 9, y: 28},
      path: [
        {x: 9, y: 29},
        {x: 9, y: 30},
        {x: 9, y: 29},
        {x: 9, y: 28}
      ],
      mirrored: false,
      sprite: 'zombie_idle_anim', 
      hit: false,
      health: 3
    },
    { 
      rect: {}, 
      position: {x: 11, y: 31},
      path: [
        {x: 12, y: 31},
        {x: 11, y: 31}
      ],
      mirrored: false,
      sprite: 'swampy_idle_anim', 
      hit: false,
      health: 3
    },
    { 
      rect: {}, 
      position: {x: 14, y: 29},
      path: [
        {x: 13, y: 29},
        {x: 14, y: 29}
      ],
      mirrored: false,
      sprite: 'swampy_idle_anim', 
      hit: false,
      health: 3
    },
    { 
      rect: {}, 
      position: {x: 15, y: 30},
      path: [
        {x: 15, y: 31},
        {x: 16, y: 31},
        {x: 15, y: 31},
        {x: 15, y: 30}
      ],
      mirrored: false,
      sprite: 'zombie_idle_anim', 
      hit: false,
      health: 3
    },
    { 
      rect: {}, 
      position: {x: 9, y: 32},
      path: [
        {x: 9, y: 33},
        {x: 9, y: 32}
      ],
      mirrored: false,
      sprite: 'big_zombie_idle_anim', 
      hit: false,
      health: 5
    },
  ],
  tiles: [
    { position: {x: -1, y: 0}, sprite: 'wall_side_top_left' },
    { position: {x: 0, y: 0}, sprite: 'wall_top_left' },
    { position: {x: 1, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 2, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 3, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 4, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 5, y: 0}, sprite: 'wall_top_mid'},
    { position: {x: 6, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 7, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 8, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 9, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 10, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 11, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 12, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 13, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 14, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 15, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 16, y: 0}, sprite: 'wall_top_mid' },
    { position: {x: 17, y: 0}, sprite: 'wall_side_top_right' },

    { position: {x: -1, y: 1}, sprite: 'wall_side_mid_left' },
    { position: {x: 0, y: 1}, sprite: 'wall_goo_mid' },
    { position: {x: 1, y: 1}, sprite: 'wall_mid' },
    { position: {x: 2, y: 1}, sprite: 'wall_banner_blue' },
    { position: {x: 3, y: 1}, sprite: 'wall_mid' },
    { position: {x: 4, y: 1}, sprite: 'wall_mid' },
    { position: {x: 5, y: 1}, sprite: 'wall_mid'},
    { position: {x: 6, y: 1}, sprite: 'wall_banner_blue' },
    { position: {x: 7, y: 1}, sprite: 'wall_mid' },
    { position: {x: 8, y: 1}, sprite: 'wall_mid' },
    { position: {x: 9, y: 1}, sprite: 'wall_mid' },
    { position: {x: 10, y: 1}, sprite: 'wall_banner_blue' },
    { position: {x: 11, y: 1}, sprite: 'wall_mid' },
    { position: {x: 12, y: 1}, sprite: 'wall_mid' },
    { position: {x: 13, y: 1}, sprite: 'wall_mid' },
    { position: {x: 14, y: 1}, sprite: 'wall_banner_blue' },
    { position: {x: 15, y: 1}, sprite: 'wall_mid' },
    { position: {x: 16, y: 1}, sprite: 'wall_right' },
    { position: {x: 17, y: 1}, sprite: 'wall_side_mid_right' },

    { position: {x: -1, y: 2}, sprite: 'wall_side_mid_left' },
    { position: {x: 0, y: 2}, sprite: 'wall_goo_base' },
    { position: {x: 1, y: 2}, sprite: 'floor_r' },
    { position: {x: 2, y: 2}, sprite: 'floor_r' },
    { position: {x: 3, y: 2}, sprite: 'floor_r' },
    { position: {x: 4, y: 2}, sprite: 'floor_r' },
    { position: {x: 5, y: 2}, sprite: 'floor_r' },
    { position: {x: 6, y: 2}, sprite: 'floor_r' },
    { position: {x: 7, y: 2}, sprite: 'floor_r' },
    { position: {x: 8, y: 2}, sprite: 'floor_r' },
    { position: {x: 9, y: 2}, sprite: 'floor_r' },
    { position: {x: 10, y: 2}, sprite: 'floor_r' },
    { position: {x: 11, y: 2}, sprite: 'floor_r' },
    { position: {x: 12, y: 2}, sprite: 'floor_r' },
    { position: {x: 13, y: 2}, sprite: 'floor_r' },
    { position: {x: 14, y: 2}, sprite: 'floor_r' },
    { position: {x: 15, y: 2}, sprite: 'floor_r' },
    { position: {x: 16, y: 2}, sprite: 'floor_r' },
    { position: {x: 17, y: 2}, sprite: 'wall_side_mid_right' },

    { position: {x: -1, y: 3}, sprite: 'wall_side_mid_left' },
    { position: {x: 0, y: 3}, sprite: 'floor_r' },
    { position: {x: 1, y: 3}, sprite: 'floor_r' },
    { position: {x: 2, y: 3}, sprite: 'floor_r' },
    { position: {x: 3, y: 3}, sprite: 'floor_r' },
    { position: {x: 4, y: 3}, sprite: 'floor_r' },
    { position: {x: 4, y: 3}, sprite: 'floor_r' },
    { position: {x: 5, y: 3}, sprite: 'floor_r' },
    { position: {x: 6, y: 3}, sprite: 'floor_r' },
    { position: {x: 7, y: 3}, sprite: 'floor_r' },
    { position: {x: 8, y: 3}, sprite: 'floor_r' },
    { position: {x: 9, y: 3}, sprite: 'floor_r' },
    { position: {x: 10, y: 3}, sprite: 'floor_r' },
    { position: {x: 11, y: 3}, sprite: 'floor_r' },
    { position: {x: 12, y: 3}, sprite: 'floor_r' },
    { position: {x: 13, y: 3}, sprite: 'floor_r' },
    { position: {x: 14, y: 3}, sprite: 'floor_r' },
    { position: {x: 15, y: 3}, sprite: 'floor_r' },
    { position: {x: 16, y: 3}, sprite: 'floor_r' },
    { position: {x: 17, y: 3}, sprite: 'wall_side_mid_right' },

    { position: {x: -1, y: 4}, sprite: 'wall_side_mid_left' },
    { position: {x: 0, y: 4}, sprite: 'floor_r' },
    { position: {x: 1, y: 4}, sprite: 'floor_r' },
    { position: {x: 2, y: 4}, sprite: 'floor_r' },
    { position: {x: 3, y: 4}, sprite: 'floor_r' },
    { position: {x: 4, y: 4}, sprite: 'floor_r' },
    { position: {x: 5, y: 4}, sprite: 'floor_r' },
    { position: {x: 6, y: 4}, sprite: 'floor_r' },
    { position: {x: 7, y: 4}, sprite: 'floor_r' },
    { position: {x: 8, y: 4}, sprite: 'floor_r' },
    { position: {x: 9, y: 4}, sprite: 'floor_r' },
    { position: {x: 10, y: 4}, sprite: 'floor_r' },
    { position: {x: 11, y: 4}, sprite: 'floor_r' },
    { position: {x: 12, y: 4}, sprite: 'floor_r' },
    { position: {x: 13, y: 4}, sprite: 'floor_r' },
    { position: {x: 14, y: 4}, sprite: 'floor_r' },
    { position: {x: 15, y: 4}, sprite: 'floor_r' },
    { position: {x: 16, y: 4}, sprite: 'floor_r' },
    { position: {x: 17, y: 4}, sprite: 'wall_side_mid_right' },

    { position: {x: 0, y: 4}, sprite: 'wall_top_left' },
    { position: {x: 1, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 2, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 3, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 4, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 5, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 6, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 7, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 8, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 9, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 10, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 11, y: 5}, sprite: 'doors_small_frame_left' },
    { position: {x: 12, y: 5}, sprite: 'floor_r' },
    { position: {x: 12, y: 5}, sprite: 'doors_small_leaf_closed', openedBy: 'proximity'},
    { position: {x: 13, y: 5}, sprite: 'doors_small_frame_right' },
    { position: {x: 14, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 15, y: 4}, sprite: 'wall_top_mid' },
    { position: {x: 16, y: 4}, sprite: 'wall_top_right' },

    { position: {x: -1, y: 5}, sprite: 'wall_side_front_left' }, 
    { position: {x: 0, y: 5}, sprite: 'wall_mid' },
    { position: {x: 1, y: 5}, sprite: 'wall_mid' },
    { position: {x: 2, y: 5}, sprite: 'wall_mid' },
    { position: {x: 3, y: 5}, sprite: 'wall_mid' },
    { position: {x: 4, y: 5}, sprite: 'wall_mid' },
    { position: {x: 5, y: 5}, sprite: 'wall_mid'},
    { position: {x: 6, y: 5}, sprite: 'wall_mid' },
    { position: {x: 7, y: 5}, sprite: 'wall_corner_right' },
    { position: {x: 8, y: 5}, sprite: 'wall_left' },
    { position: {x: 9, y: 5}, sprite: 'wall_mid' },
    { position: {x: 10, y: 5}, sprite: 'wall_banner_green' },
    { position: {x: 14, y: 5}, sprite: 'wall_banner_green' },
    { position: {x: 15, y: 5}, sprite: 'wall_mid' },
    { position: {x: 16, y: 5}, sprite: 'wall_right' },
    { position: {x: 17, y: 5}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 6}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 6}, sprite: 'floor_r' },
    { position: {x: 9, y: 6}, sprite: 'floor_r' },
    { position: {x: 10, y: 6}, sprite: 'floor_r' },
    { position: {x: 11, y: 6}, sprite: 'floor_r' },
    { position: {x: 12, y: 6}, sprite: 'floor_r' },
    { position: {x: 13, y: 6}, sprite: 'floor_r' },
    { position: {x: 14, y: 6}, sprite: 'floor_r' },
    { position: {x: 15, y: 6}, sprite: 'floor_r' },
    { position: {x: 16, y: 6}, sprite: 'floor_r' },
    { position: {x: 16, y: 6}, sprite: 'skull' },
    { position: {x: 17, y: 6}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 7}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 7}, sprite: 'floor_r' },
    { position: {x: 9, y: 7}, sprite: 'floor_r' },
    { position: {x: 10, y: 7}, sprite: 'floor_r' },
    { position: {x: 11, y: 7}, sprite: 'floor_r' },
    { position: {x: 12, y: 7}, sprite: 'floor_r' },
    { position: {x: 13, y: 7}, sprite: 'floor_r' },
    { position: {x: 14, y: 7}, sprite: 'floor_r' },
    { position: {x: 15, y: 7}, sprite: 'floor_r' },
    { position: {x: 16, y: 7}, sprite: 'floor_r' },
    { position: {x: 17, y: 7}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 8}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 8}, sprite: 'floor_r' },
    { position: {x: 9, y: 8}, sprite: 'floor_r' },
    { position: {x: 10, y: 8}, sprite: 'floor_r' },
    { position: {x: 11, y: 8}, sprite: 'floor_r' },
    { position: {x: 12, y: 8}, sprite: 'floor_r' },
    { position: {x: 13, y: 8}, sprite: 'floor_r' },
    { position: {x: 14, y: 8}, sprite: 'floor_r' },
    { position: {x: 15, y: 8}, sprite: 'floor_r' },
    { position: {x: 16, y: 8}, sprite: 'floor_r' },
    { position: {x: 17, y: 8}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 9}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 9}, sprite: 'floor_r' },
    { position: {x: 9, y: 9}, sprite: 'floor_r' },
    { position: {x: 10, y: 9}, sprite: 'floor_r' },
    { position: {x: 11, y: 9}, sprite: 'floor_r' },
    { position: {x: 12, y: 9}, sprite: 'floor_r' },
    { position: {x: 12, y: 9}, sprite: 'wall_column_top' },
    { position: {x: 13, y: 9}, sprite: 'floor_r' },
    { position: {x: 14, y: 9}, sprite: 'floor_r' },
    { position: {x: 15, y: 9}, sprite: 'floor_r' },
    { position: {x: 16, y: 9}, sprite: 'floor_r' },
    { position: {x: 17, y: 9}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 10}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 10}, sprite: 'floor_r' },
    { position: {x: 9, y: 10}, sprite: 'floor_r' },
    { position: {x: 10, y: 10}, sprite: 'floor_r' },
    { position: {x: 11, y: 10}, sprite: 'floor_r' },
    { position: {x: 12, y: 10}, sprite: 'wall_column_mid' },
    { position: {x: 13, y: 10}, sprite: 'floor_r' },
    { position: {x: 14, y: 10}, sprite: 'floor_r' },
    { position: {x: 15, y: 10}, sprite: 'floor_r' },
    { position: {x: 16, y: 10}, sprite: 'floor_r' },
    { position: {x: 17, y: 10}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 11}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 11}, sprite: 'floor_r' },
    { position: {x: 9, y: 11}, sprite: 'floor_r' },
    { position: {x: 10, y: 11}, sprite: 'floor_r' },
    { position: {x: 11, y: 11}, sprite: 'floor_r' },
    { position: {x: 12, y: 11}, sprite: 'wall_column_base' },
    { position: {x: 13, y: 11}, sprite: 'floor_r' },
    { position: {x: 14, y: 11}, sprite: 'floor_r' },
    { position: {x: 15, y: 11}, sprite: 'floor_r' },
    { position: {x: 16, y: 11}, sprite: 'floor_r' },
    { position: {x: 17, y: 11}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 12}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 12}, sprite: 'floor_r' },
    { position: {x: 9, y: 12}, sprite: 'floor_r' },
    { position: {x: 10, y: 12}, sprite: 'floor_r' },
    { position: {x: 11, y: 12}, sprite: 'floor_r' },
    { position: {x: 12, y: 12}, sprite: 'floor_r' },
    { position: {x: 13, y: 12}, sprite: 'floor_r' },
    { position: {x: 14, y: 12}, sprite: 'floor_r' },
    { position: {x: 15, y: 12}, sprite: 'floor_r' },
    { position: {x: 16, y: 12}, sprite: 'floor_r' },
    { position: {x: 17, y: 12}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 13}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 13}, sprite: 'floor_r' },
    { position: {x: 9, y: 13}, sprite: 'floor_r' },
    { position: {x: 10, y: 13}, sprite: 'floor_r' },
    { position: {x: 11, y: 13}, sprite: 'floor_r' },
    { position: {x: 12, y: 13}, sprite: 'floor_r' },
    { position: {x: 13, y: 13}, sprite: 'floor_r' },
    { position: {x: 14, y: 13}, sprite: 'floor_r' },
    { position: {x: 15, y: 13}, sprite: 'floor_r' },
    { position: {x: 16, y: 13}, sprite: 'floor_r' },
    { position: {x: 17, y: 13}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 14}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 14}, sprite: 'floor_r' },
    { position: {x: 9, y: 14}, sprite: 'floor_r' },
    { position: {x: 10, y: 14}, sprite: 'floor_r' },
    { position: {x: 11, y: 14}, sprite: 'floor_r' },
    { position: {x: 12, y: 14}, sprite: 'floor_r' },
    { position: {x: 13, y: 14}, sprite: 'floor_r' },
    { position: {x: 14, y: 14}, sprite: 'floor_r' },
    { position: {x: 15, y: 14}, sprite: 'floor_r' },
    { position: {x: 16, y: 14}, sprite: 'floor_r' },
    { position: {x: 17, y: 14}, sprite: 'wall_side_mid_right' },
    
    { position: {x: 8, y: 14}, sprite: 'wall_top_left' },
    { position: {x: 9, y: 14}, sprite: 'wall_top_mid' },
    { position: {x: 10, y: 14}, sprite: 'wall_top_mid' },
    { position: {x: 11, y: 15}, sprite: 'doors_small_frame_left' },
    { position: {x: 12, y: 15}, sprite: 'floor_r' },
    { position: {x: 12, y: 15}, sprite: 'doors_small_leaf_closed', openedBy: 'necromancer_idle_anim' },
    { position: {x: 13, y: 15}, sprite: 'doors_small_frame_right' },
    { position: {x: 14, y: 14}, sprite: 'wall_top_mid' },
    { position: {x: 15, y: 14}, sprite: 'wall_top_mid' },
    { position: {x: 16, y: 14}, sprite: 'wall_top_right' },

    { position: {x: 7, y: 15}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 15}, sprite: 'wall_left' },
    { position: {x: 9, y: 15}, sprite: 'wall_mid' },
    { position: {x: 10, y: 15}, sprite: 'wall_banner_yellow' },
    { position: {x: 14, y: 15}, sprite: 'wall_banner_yellow' },
    { position: {x: 15, y: 15}, sprite: 'wall_mid' },
    { position: {x: 16, y: 15}, sprite: 'wall_right' },
    { position: {x: 17, y: 15}, sprite: 'wall_side_mid_right' },    

    { position: {x: 7, y: 16}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 16}, sprite: 'floor_r' },
    { position: {x: 9, y: 16}, sprite: 'floor_r' },
    { position: {x: 10, y: 16}, sprite: 'floor_r' },
    { position: {x: 11, y: 16}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 12, y: 16}, sprite: 'floor_r' },
    { position: {x: 13, y: 16}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 14, y: 16}, sprite: 'void' },
    { position: {x: 14, y: 16}, sprite: 'edge' },
    { position: {x: 15, y: 16}, sprite: 'void' },
    { position: {x: 15, y: 16}, sprite: 'edge' },
    { position: {x: 16, y: 16}, sprite: 'void' },
    { position: {x: 16, y: 16}, sprite: 'edge' },
    { position: {x: 17, y: 16}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 17}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 17}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 9, y: 17}, sprite: 'void' },
    { position: {x: 9, y: 17}, sprite: 'edge' },
    { position: {x: 10, y: 17}, sprite: 'void' },
    { position: {x: 10, y: 17}, sprite: 'edge' },
    { position: {x: 11, y: 17}, sprite: 'floor_spikes_anim', triggered: true },
    { position: {x: 12, y: 17}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 13, y: 17}, sprite: 'floor_spikes_anim', triggered: true },
    { position: {x: 14, y: 17}, sprite: 'void' },
    { position: {x: 15, y: 17}, sprite: 'floor_r' },
    { position: {x: 15, y: 17}, sprite: 'chest_closed', reward: 'ui_heart_full', claimed: false},
    { position: {x: 16, y: 17}, sprite: 'void' },
    { position: {x: 17, y: 17}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 18}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 18}, sprite: 'floor_r' },
    { position: {x: 9, y: 18}, sprite: 'void' },
    { position: {x: 10, y: 18}, sprite: 'void' },
    { position: {x: 11, y: 18}, sprite: 'void' },
    { position: {x: 11, y: 18}, sprite: 'edge' },
    { position: {x: 12, y: 18}, sprite: 'floor_r' },
    { position: {x: 13, y: 18}, sprite: 'void' },
    { position: {x: 13, y: 18}, sprite: 'edge' },
    { position: {x: 14, y: 18}, sprite: 'void' },
    { position: {x: 15, y: 18}, sprite: 'floor_r' },
    { position: {x: 16, y: 18}, sprite: 'void' },
    { position: {x: 17, y: 18}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 19}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 19}, sprite: 'floor_spikes_anim', triggered: true },
    { position: {x: 9, y: 19}, sprite: 'void' },
    { position: {x: 10, y: 19}, sprite: 'void' },
    { position: {x: 11, y: 19}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 12, y: 19}, sprite: 'floor_r' },
    { position: {x: 12, y: 19}, sprite: 'wall_column_top' },
    { position: {x: 13, y: 19}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 14, y: 19}, sprite: 'void' },
    { position: {x: 15, y: 19}, sprite: 'floor_spikes_anim', triggered: true },
    { position: {x: 16, y: 19}, sprite: 'void' },
    { position: {x: 17, y: 19}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 20}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 20}, sprite: 'floor_r' },
    { position: {x: 9, y: 20}, sprite: 'void' },
    { position: {x: 10, y: 20}, sprite: 'void' },
    { position: {x: 11, y: 20}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 12, y: 20}, sprite: 'wall_column_mid' },
    { position: {x: 13, y: 20}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 14, y: 20}, sprite: 'void' },
    { position: {x: 15, y: 20}, sprite: 'floor_r' },
    { position: {x: 16, y: 20}, sprite: 'void' },
    { position: {x: 17, y: 20}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 21}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 21}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 9, y: 21}, sprite: 'void' },
    { position: {x: 10, y: 21}, sprite: 'void' },
    { position: {x: 11, y: 21}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 12, y: 21}, sprite: 'wall_column_base' },
    { position: {x: 13, y: 21}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 14, y: 21}, sprite: 'void' },
    { position: {x: 15, y: 21}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 16, y: 21}, sprite: 'void' },
    { position: {x: 17, y: 21}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 22}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 22}, sprite: 'floor_r' },
    { position: {x: 9, y: 22}, sprite: 'void' },
    { position: {x: 10, y: 22}, sprite: 'void' },
    { position: {x: 11, y: 22}, sprite: 'void' },
    { position: {x: 11, y: 22}, sprite: 'edge' },
    { position: {x: 12, y: 22}, sprite: 'floor_r' },
    { position: {x: 13, y: 22}, sprite: 'void' },
    { position: {x: 13, y: 22}, sprite: 'edge' },
    { position: {x: 14, y: 22}, sprite: 'void' },
    { position: {x: 15, y: 22}, sprite: 'floor_r' },
    { position: {x: 16, y: 22}, sprite: 'void' },
    { position: {x: 17, y: 22}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 23}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 23}, sprite: 'floor_r' },
    { position: {x: 9, y: 23}, sprite: 'floor_r' },
    { position: {x: 10, y: 23}, sprite: 'floor_r' },
    { position: {x: 11, y: 23}, sprite: 'floor_r' },
    { position: {x: 12, y: 23}, sprite: 'floor_r' },
    { position: {x: 13, y: 23}, sprite: 'floor_r' },
    { position: {x: 14, y: 23}, sprite: 'floor_r' },
    { position: {x: 15, y: 23}, sprite: 'floor_r' },
    { position: {x: 16, y: 23}, sprite: 'floor_r' },
    { position: {x: 17, y: 23}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 24}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 24}, sprite: 'floor_r' },
    { position: {x: 9, y: 24}, sprite: 'floor_r' },
    { position: {x: 10, y: 24}, sprite: 'floor_r' },
    { position: {x: 11, y: 24}, sprite: 'floor_r' },
    { position: {x: 12, y: 24}, sprite: 'floor_r' },
    { position: {x: 13, y: 24}, sprite: 'floor_r' },
    { position: {x: 14, y: 24}, sprite: 'floor_r' },
    { position: {x: 15, y: 24}, sprite: 'floor_r' },
    { position: {x: 16, y: 24}, sprite: 'floor_r' },
    { position: {x: 17, y: 24}, sprite: 'wall_side_mid_right' },
    
    { position: {x: 8, y: 24}, sprite: 'wall_top_left' },
    { position: {x: 9, y: 24}, sprite: 'wall_top_mid' },
    { position: {x: 10, y: 24}, sprite: 'wall_top_mid' },
    { position: {x: 11, y: 25}, sprite: 'doors_small_frame_left' },
    { position: {x: 12, y: 25}, sprite: 'floor_r' },
    { position: {x: 12, y: 25}, sprite: 'doors_small_leaf_closed', openedBy: 'proximity' },
    { position: {x: 13, y: 25}, sprite: 'doors_small_frame_right' },
    { position: {x: 14, y: 24}, sprite: 'wall_top_mid' },
    { position: {x: 15, y: 24}, sprite: 'wall_top_mid' },
    { position: {x: 16, y: 24}, sprite: 'wall_top_right' },

    { position: {x: 7, y: 25}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 25}, sprite: 'wall_left' },
    { position: {x: 9, y: 25}, sprite: 'wall_mid' },
    { position: {x: 10, y: 25}, sprite: 'wall_banner_red' },
    { position: {x: 14, y: 25}, sprite: 'wall_banner_red' },
    { position: {x: 15, y: 25}, sprite: 'wall_mid' },
    { position: {x: 16, y: 25}, sprite: 'wall_right' },
    { position: {x: 17, y: 25}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 26}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 26}, sprite: 'floor_r' },
    { position: {x: 9, y: 26}, sprite: 'floor_r' },
    { position: {x: 10, y: 26}, sprite: 'floor_r' },
    { position: {x: 11, y: 26}, sprite: 'floor_r' },
    { position: {x: 12, y: 26}, sprite: 'floor_r' },
    { position: {x: 13, y: 26}, sprite: 'floor_r' },
    { position: {x: 14, y: 26}, sprite: 'void' },
    { position: {x: 14, y: 26}, sprite: 'edge' },
    { position: {x: 15, y: 26}, sprite: 'floor_r' },
    { position: {x: 16, y: 26}, sprite: 'floor_r' },
    { position: {x: 16, y: 26}, sprite: 'chest_closed', reward: 'weapon_golden_sword', claimed: false},
    { position: {x: 17, y: 26}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 27}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 27}, sprite: 'floor_r' },
    { position: {x: 9, y: 27}, sprite: 'floor_r' },
    { position: {x: 10, y: 27}, sprite: 'floor_r' },
    { position: {x: 11, y: 27}, sprite: 'floor_r' },
    { position: {x: 12, y: 27}, sprite: 'floor_r' },
    { position: {x: 13, y: 27}, sprite: 'floor_r' },
    { position: {x: 14, y: 27}, sprite: 'void' },
    { position: {x: 15, y: 27}, sprite: 'floor_r' },
    { position: {x: 16, y: 27}, sprite: 'floor_r' },
    { position: {x: 17, y: 27}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 28}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 28}, sprite: 'floor_r' },
    { position: {x: 9, y: 28}, sprite: 'floor_r' },
    { position: {x: 10, y: 28}, sprite: 'void' },
    { position: {x: 10, y: 28}, sprite: 'edge' },
    { position: {x: 11, y: 28}, sprite: 'void' },
    { position: {x: 11, y: 28}, sprite: 'edge' },
    { position: {x: 12, y: 28}, sprite: 'void' },
    { position: {x: 12, y: 28}, sprite: 'edge' },
    { position: {x: 13, y: 28}, sprite: 'void' },
    { position: {x: 13, y: 28}, sprite: 'edge' },
    { position: {x: 14, y: 28}, sprite: 'void' },
    { position: {x: 15, y: 28}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 16, y: 28}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 17, y: 28}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 29}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 29}, sprite: 'floor_r' },
    { position: {x: 9, y: 29}, sprite: 'floor_r' },
    { position: {x: 10, y: 29}, sprite: 'floor_r' },
    { position: {x: 11, y: 29}, sprite: 'floor_r' },
    { position: {x: 12, y: 29}, sprite: 'floor_r' },
    { position: {x: 12, y: 29}, sprite: 'wall_column_top' },
    { position: {x: 13, y: 29}, sprite: 'floor_r' },
    { position: {x: 14, y: 29}, sprite: 'floor_r' },
    { position: {x: 15, y: 29}, sprite: 'floor_r' },
    { position: {x: 16, y: 29}, sprite: 'floor_r' },
    { position: {x: 17, y: 29}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 30}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 30}, sprite: 'floor_r' },
    { position: {x: 9, y: 30}, sprite: 'floor_r' },
    { position: {x: 10, y: 30}, sprite: 'floor_r' },
    { position: {x: 11, y: 30}, sprite: 'floor_r' },
    { position: {x: 12, y: 30}, sprite: 'wall_column_mid' },
    { position: {x: 13, y: 30}, sprite: 'floor_r' },
    { position: {x: 14, y: 30}, sprite: 'floor_r' },
    { position: {x: 15, y: 30}, sprite: 'floor_r' },
    { position: {x: 16, y: 30}, sprite: 'floor_r' },
    { position: {x: 17, y: 30}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 31}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 31}, sprite: 'void' },
    { position: {x: 8, y: 31}, sprite: 'edge' },
    { position: {x: 9, y: 31}, sprite: 'void' },
    { position: {x: 9, y: 31}, sprite: 'edge' },
    { position: {x: 10, y: 31}, sprite: 'void' },
    { position: {x: 10, y: 31}, sprite: 'edge' },
    { position: {x: 11, y: 31}, sprite: 'floor_r' },
    { position: {x: 12, y: 31}, sprite: 'wall_column_base' },
    { position: {x: 13, y: 31}, sprite: 'floor_r' },
    { position: {x: 14, y: 31}, sprite: 'void' },
    { position: {x: 14, y: 31}, sprite: 'edge' },
    { position: {x: 15, y: 31}, sprite: 'floor_r' },
    { position: {x: 16, y: 31}, sprite: 'floor_r' },
    { position: {x: 17, y: 31}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 32}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 32}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 9, y: 32}, sprite: 'floor_r' },
    { position: {x: 10, y: 32}, sprite: 'void' },
    { position: {x: 11, y: 32}, sprite: 'void' },
    { position: {x: 11, y: 32}, sprite: 'edge' },
    { position: {x: 12, y: 32}, sprite: 'void' },
    { position: {x: 12, y: 32}, sprite: 'edge' },
    { position: {x: 13, y: 32}, sprite: 'void' },
    { position: {x: 13, y: 32}, sprite: 'edge' },
    { position: {x: 14, y: 32}, sprite: 'void' },
    { position: {x: 15, y: 32}, sprite: 'floor_r' },
    { position: {x: 16, y: 32}, sprite: 'floor_r' },
    { position: {x: 17, y: 32}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 33}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 33}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 9, y: 33}, sprite: 'floor_r' },
    { position: {x: 10, y: 33}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 11, y: 33}, sprite: 'floor_r' },
    { position: {x: 12, y: 33}, sprite: 'floor_r' },
    { position: {x: 13, y: 33}, sprite: 'floor_r' },
    { position: {x: 14, y: 33}, sprite: 'floor_r' },
    { position: {x: 15, y: 33}, sprite: 'floor_r' },
    { position: {x: 16, y: 33}, sprite: 'floor_r' },
    { position: {x: 17, y: 33}, sprite: 'wall_side_mid_right' },

    { position: {x: 7, y: 34}, sprite: 'wall_side_mid_left' },
    { position: {x: 8, y: 34}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 9, y: 34}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 10, y: 34}, sprite: 'floor_spikes_anim', triggered: false },
    { position: {x: 11, y: 34}, sprite: 'floor_r' },
    { position: {x: 12, y: 34}, sprite: 'floor_r' },
    { position: {x: 13, y: 34}, sprite: 'floor_r' },
    { position: {x: 14, y: 34}, sprite: 'floor_r' },
    { position: {x: 15, y: 34}, sprite: 'floor_r' },
    { position: {x: 16, y: 34}, sprite: 'floor_r' },
    { position: {x: 17, y: 34}, sprite: 'wall_side_mid_right' },
    
    { position: {x: 8, y: 34}, sprite: 'wall_top_left' },
    { position: {x: 9, y: 34}, sprite: 'wall_top_mid' },
    { position: {x: 10, y: 34}, sprite: 'wall_top_mid' },
    { position: {x: 11, y: 35}, sprite: 'doors_small_frame_left' },
    { position: {x: 12, y: 35}, sprite: 'floor_r' },
    { position: {x: 12, y: 35}, sprite: 'doors_small_leaf_closed', openedBy: 'big_zombie_idle_anim' },
    { position: {x: 13, y: 35}, sprite: 'doors_small_frame_right' },
    { position: {x: 14, y: 34}, sprite: 'wall_top_mid' },
    { position: {x: 15, y: 34}, sprite: 'wall_top_mid' },
    { position: {x: 16, y: 34}, sprite: 'wall_top_right' },

    { position: {x: 7, y: 35}, sprite: 'wall_side_front_left' },
    { position: {x: 8, y: 35}, sprite: 'wall_left' },
    { position: {x: 9, y: 35}, sprite: 'wall_mid' },
    { position: {x: 10, y: 35}, sprite: 'wall_inner_corner_mid_right' },
    { position: {x: 14, y: 35}, sprite: 'wall_inner_corner_mid_left' },
    { position: {x: 15, y: 35}, sprite: 'wall_mid' },
    { position: {x: 16, y: 35}, sprite: 'wall_right' },
    { position: {x: 17, y: 35}, sprite: 'wall_side_front_right' },

    { position: {x: 10, y: 36}, sprite: 'wall_side_mid_left' },
    { position: {x: 11, y: 36}, sprite: 'floor_r' },
    { position: {x: 12, y: 36}, sprite: 'floor_r' },
    { position: {x: 13, y: 36}, sprite: 'floor_r' },
    { position: {x: 14, y: 36}, sprite: 'wall_side_mid_right' },

    { position: {x: 10, y: 37}, sprite: 'wall_side_mid_left' },
    { position: {x: 11, y: 37}, sprite: 'floor_r' },
    { position: {x: 12, y: 37}, sprite: 'floor_r' },
    { position: {x: 12, y: 37}, sprite: 'chest_closed', reward: 'princess', claimed: false },
    { position: {x: 13, y: 37}, sprite: 'floor_r' },
    { position: {x: 14, y: 37}, sprite: 'wall_side_mid_right' },

    { position: {x: 10, y: 38}, sprite: 'wall_side_mid_left' },
    { position: {x: 11, y: 38}, sprite: 'floor_r' },
    { position: {x: 12, y: 38}, sprite: 'floor_r' },
    { position: {x: 13, y: 38}, sprite: 'floor_r' },
    { position: {x: 14, y: 38}, sprite: 'wall_side_mid_right' },

    { position: {x: 11, y: 38}, sprite: 'wall_top_left' },
    { position: {x: 12, y: 38}, sprite: 'wall_top_mid' },
    { position: {x: 13, y: 38}, sprite: 'wall_top_right' },

    { position: {x: 10, y: 39}, sprite: 'wall_side_front_left' },
    { position: {x: 11, y: 39}, sprite: 'wall_left' },
    { position: {x: 12, y: 39}, sprite: 'wall_mid' },
    { position: {x: 13, y: 39}, sprite: 'wall_right' },
    { position: {x: 14, y: 39}, sprite: 'wall_side_front_right' },
  ]
}