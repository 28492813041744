import { decorate, action, observable } from 'mobx';
import initial from './GameStore.initial';
import helpers from '../helpers';

class GameStore {
  constructor() {
    this.reset();
  }

  reset() {
    this.rect = helpers.clone(initial.rect);
    this.starting = helpers.clone(initial.starting);
    this.started = helpers.clone(initial.started);
    this.completed = helpers.clone(initial.completed);
    this.mouse = helpers.clone(initial.mouse);
    this.player = helpers.clone(initial.player);
    this.monsters = helpers.clone(initial.monsters);
    this.tiles = helpers.clone(initial.tiles);
  }

	// Store get functions used by multiple components...
  getTilesByPosition = (position) => {
    let matches = [];

    for(const tile of this.tiles) {
      if(JSON.stringify(tile.position) === JSON.stringify(position)) {
        matches.push(tile);
      }
    }

    return matches;
  }

  getTilesBySprite = (sprite) => {
    let matches = [];

    for(const tile of this.tiles) {
      if(tile.sprite === sprite) {
        matches.push(tile);
      }
    }

    return matches;
  }

  getTilesAroundPosition = (position, sprite = '', distance = 3) => {
    let matches = [];

    for(const tile of this.tiles) {
      // If sprite matches
      if(sprite === '' || sprite === tile.sprite) {
        // 8,26 vs 8,17... 1,3 vs 11,16
        // If tile within distance
        if(((position.x - tile.position.x) <= distance && (position.x - tile.position.x) >= -(distance)) && ((position.y - tile.position.y) <= distance & (position.y - tile.position.y) >= -(distance))) {
          matches.push(tile);
        }
      }
    }

    return matches;
  }


  // Store manipulating functions used by multiple components...
  hitPlayer = () => {
    if(!this.player.hit && this.player.health > 0) {
      console.log('health', this.player.health);

      this.player.hit = true;
      this.player.health = this.player.health - 1;

      if(this.player.health <= 0) {
        this.player.movable = false;
        const sound = new Audio('static/sound/dead_player_1.mp3');
        sound.play();
      } else {
        const sound = new Audio('static/sound/hit_player_1.mp3');
        sound.play();
      }
        
      setTimeout(() => {
        this.player.hit = false;

        if(this.player.health <= 0) {
          this.respawnPlayer();
        }
      }, 1000);
    }
  }

  respawnPlayer = () => {
    // Reset health, position, and movable
    this.player.health = this.player.maxHealth;
    this.player.position.x = initial.player.position.x;
    this.player.position.y = initial.player.position.y;
    this.player.movable = initial.player.movable;
    this.player.respawns = this.player.respawns + 1;

    // Give bigger sword if more than 3 respawns...
    if(this.player.respawns >= 3) {
      // this.player.weapon.sprite = 'weapon_anime_sword'; // TODO - make sprite overlapping work with it...
    }

    // Scroll to player
    helpers.scrollToOffset(helpers.getOffsetForPosition(this.player.position));
  }

  // Computed vars... dead, angle, mirrored...
};

decorate(GameStore, {
  rect: observable,
  starting: observable,
  started: observable,
  completed: observable,
  mouse: observable,
  player: observable,
  monsters: observable,
  tiles: observable,
  reset: action,
	hitPlayer: action,
	respawnPlayer: action
});

const gameStore = new GameStore();

export const initialGameStore = initial;

export default gameStore;