import ReactDOM from 'react-dom';
import config from './config';

export const animateScroll = (to, duration = 1000, linear = true) => {
	const start = window.pageYOffset;
	const timeoutInterval = 10;
	const changeInterval = (to - start) / (duration / timeoutInterval);
	let currentTime = 0;
	let currentScroll = start;
	
	var animateScrollLoop = () => {        
	  currentTime = currentTime+timeoutInterval;
	  currentScroll = currentScroll+changeInterval;
	  window.scrollTo(0, currentScroll);
	  if(currentTime < duration) {
	    setTimeout(animateScrollLoop, timeoutInterval);
	  }
	};

	animateScrollLoop();
}

export const objectsAreEqual = (object1, object2) => {
	return (JSON.stringify(object1) === JSON.stringify(object2));
}

export const calculateRectOverlap = (fromRect, toRect) => {
  // Idea is to calculate coverage of fromRect by the toRect and return as percentage
  const fromRectVolume = fromRect.width * fromRect.height;
  let coverRect = {
    width: 0,
    height: 0
  }

  // Must be a smarter way to do this...
  // TODO... DRY

  // Calculating width coverage...
  if(fromRect.right > toRect.left && fromRect.right < toRect.right) { // If end of sword on monster...
    coverRect.width = fromRect.right - toRect.left;
  }
  if(fromRect.left < toRect.right && fromRect.left > toRect.left) { // If handle of sword on monster...
    coverRect.width = toRect.right - fromRect.left;
  }
  if(fromRect.right > toRect.right && fromRect.left < toRect.left) { // If sword passing through monster...
    coverRect.width = toRect.right - toRect.left;
  }

  // Calculating height coverage...
  if(fromRect.bottom > toRect.top && fromRect.bottom < toRect.bottom) { // If end of sword on monster...
    coverRect.height = fromRect.bottom - toRect.top;
  }
  if(fromRect.top < toRect.bottom && fromRect.top > toRect.top) { // If handle of sword on monster...
    coverRect.height = toRect.bottom - fromRect.top;
  }
  if(fromRect.bottom > toRect.bottom && fromRect.top < toRect.top) { // If sword passing through monster...
    coverRect.height = toRect.bottom - toRect.top;
  }

  const coverVolume = coverRect.width * coverRect.height;

  return (coverVolume / fromRectVolume) * 100;
}

export const clone = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const getAngleBetweenOffsets = (fromPosition, toPosition) => {
  const relativePosition = {
    top: (toPosition.top - fromPosition.top),
    left: (toPosition.left - fromPosition.left)
  }

  // radians
  let theta = Math.atan2(relativePosition.top, relativePosition.left);

  // non-negative
  if (theta < 0)
    theta += 2 * Math.PI;

  // radians to degrees
  theta = theta * (180 / Math.PI);

  theta = theta + 90;

  return theta;
}

export const getOffsetForPosition = (position, center = true) => {
	let offset = {
    top: ((position.y * config.tileSize)),
    left: ((position.x * config.tileSize))
  }

  if(center) {
  	offset.top = offset.top + (config.tileSize / 2);
  	offset.left = offset.left + (config.tileSize / 2);
  }

  return offset;
}

export const getPositionForOffset = (left, top) => {
  return {
    x: Math.floor(left / config.tileSize),
    y: Math.floor(top / config.tileSize)
  }
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getRectForViewport = () => {
  let rect = {};
  rect.width = window.innerWidth;
  rect.height = window.innerHeight;
  rect.top = window.pageYOffset;
  rect.bottom = rect.top + rect.height;
  rect.left = window.pageXOffset;
  rect.right = rect.left + rect.width;

  return rect;
}

export const getRectForNode = (node) => {
  return ReactDOM.findDOMNode(node).getBoundingClientRect();
}

export const scrollToOffset = (position) => {
  animateScroll(position.top+300, 175);
}

export default {
	animateScroll,
	objectsAreEqual,
	calculateRectOverlap,
  clone,
	getAngleBetweenOffsets,
	getOffsetForPosition,
	getPositionForOffset,
  getRandomInt,
  getRectForViewport,
	getRectForNode,
	scrollToOffset
}