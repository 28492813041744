import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Transition from './components/Transition';
import Game from './components/Game';
import gameStore from './stores/GameStore';
import contactStore from './stores/ContactStore';
import helpers from './helpers';

import profileImg from './assets/img/profile.svg';
import chevronImg from './assets/img/chevron.svg';
import crossImg from './assets/img/cross.svg';
import tickImg from './assets/img/tick.svg';
import gameImg from './assets/img/sword.svg';


const App = observer(
class App extends Component {
  constructor(props) {
    super(props);
    this.name = React.createRef();
    this.email = React.createRef();
    this.message = React.createRef();
  }

  startGame = (event) => {
    gameStore.starting = true;

    setTimeout(function() {
      gameStore.started = true;
    }, 250);
  }

  scrollToPortfolio = (event) => {
    event.preventDefault();
    const portfolio = document.getElementById('portfolio');
    const portfolioRect = helpers.getRectForNode(portfolio);

    helpers.animateScroll(window.pageYOffset + portfolioRect.top, 350, false);
  }

  handleContactSubmit = (event) => {
    event.preventDefault();

    // Get form data
    const {name, email, message} = this;
    const formData = new FormData();
    formData.append("name", name.value);
    formData.append("email", email.value);
    formData.append("message", message.value);

    // Prepare request
    contactStore.loading = true;
    const req = new XMLHttpRequest();

    // Handle response
    req.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        contactStore.loading = false;

        const resp = JSON.parse(this.responseText);

        if(typeof resp.errors !== 'undefined' && resp.errors.length > 0) {
          contactStore.errors = resp.errors;
          contactStore.success = false;

          setTimeout(() => {
            contactStore.errors = [];
          }, 5000);
        } else if(typeof resp.success !== 'undefined' && resp.success) {
          contactStore.errors = [];
          contactStore.success = true;

          setTimeout(() => {
            name.value = '';
            email.value = '';
            message.value = '';
            contactStore.success = false;
          }, 5000);
        }
      }
    }

    // Send request
    req.open('POST', '/mailer');
    req.send(formData);
  }

  onFocus = (event) => {
    gameStore.player.movable = false;
  }

  onBlur = (event) => {
    gameStore.player.movable = true;
  }

  render() {
    return (
      <div className={'App '+(gameStore.starting ? 'playing' : '')}>
        <div className="container">
          <div id="profile" className="section section--green">
            <div className="section__inner">
              <div className="profile">
                <Transition on="inview" type="fadescale">
                  <img src={profileImg} className="profile__image" alt="Profile" />
                </Transition>
                <Transition on="inview" type="fadescale">
                  <h1 className="section__heading">Carl Simmons</h1>
                </Transition>
                <Transition on="inview" type="fadescale">
                  <h2 className="section__subheading">Full-Stack Web Developer</h2>
                </Transition>
                <Transition on="inview" type="fadescale">
                  <p className="profile__body">I'm a developer with a passion for making websites since the age of 12; when I started making them for games I played with friends. Over time I've learnt a wide range of technologies and know when to deploy them to deliver the most effective product.</p>
                </Transition>
                <Transition on="inview" type="fadescale">
                  <div className="profile__actions">
                    <a href="#portfolio" className="button profile__action profile__action--portfolio" onClick={this.scrollToPortfolio}>
                      <span className="button__label button__label--hover"><img src={chevronImg} height="10" alt="arrow pointing down" /></span>
                      <span className="button__label button__label--default">View Portfolio</span>
                    </a>
                    <span className="profile__decision">or</span>
                    <button className="button button--retro profile__action profile__action--play" onClick={this.startGame}>
                      <span className="button__label button__label--hover"><img src={gameImg} height="20" alt="game icon" /></span>
                      <span className="button__label button__label--default">Save the Princess</span>
                    </button>
                  </div>
                </Transition>
              </div>
              {gameStore.started ? <Game /> : '' }
            </div>
          </div>
          <section id="portfolio" className="section section--latte">
            <div className="section__inner">
              <div className="portfolio">
                <Transition on="inview" type="fadetoup">
                  <h1 className="section__heading">Portfolio</h1>
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-vm.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-vm.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-vm.jpg" alt="Ventzke Media website preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">Ventzke Media</h2>
                        <p><a href="https://www.ventzke-media.de/en/" target="_blank" rel="noopener noreferrer">This project</a> is the new business site developed for Ventzke Media. I was responsible for the technology selection and the initial bulk of development for this project. Gatsby was used to server-side compile the pages to deliver incredibly fast load speeds. Strapi CMS was used to deliver GraphQL which helped to reduce compile times.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - with React/Gatsby</li>
                          <li>CMS - Strapi via GraphQL</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://www.ventzke-media.de/en/" target="_blank" rel="noopener noreferrer">https://www.ventzke-media.de/en/</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-3dconf.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-3dconf.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-3dconf.jpg" alt="3D configurator website preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">3D Configurator</h2>
                        <p><a href="https://www.ventzke-media.de/en/case-studies/franke-3d-product-configurator" target="_blank" rel="noopener noreferrer">This project</a> was developed whilst working with <a href="https://www.ventzke-media.de/en/" target="_blank" rel="noopener noreferrer">Ventzke Media</a>. I joined this project with 1 other developer and took over as lead developer. I also developed the 3D view with <a href="https://threejs.org/" target="_blank">Three.js</a> and manufacturing sheet generation, which streamlined their process. I also implemented price calculations, a custom light-weight CMS, and SAP submission. Even after my departure from the company the CEO appreciated my contributions and so I continued my role as senior developer on a freelance basis; helping to manage the other developers and continue development of React based projects.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - with React using TypeScript</li>
                          <li>API - NodeJs/Loopback</li>
                          <li>Unit Tests - Jest</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://professional-configurator.kwc.com/" target="_blank" rel="noopener noreferrer">https://professional-configurator.kwc.com/</a></li>
                          <li><a href="https://www.ventzke-media.de/en/case-studies/franke-3d-product-configurator" target="_blank" rel="noopener noreferrer">Case study</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-gds.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-gds.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-gds.jpg" alt="GDS Group website preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">GDS Group</h2>
                        <p><a href="https://gdsgroup.com/" target="_blank" rel="noopener noreferrer">This project</a> was developed whilst freelancing for GDS Group. I have worked with GDS since 2017 and over the years I have built 5+ sites and applications for them with fairly unique requirements and challenges. Each project was completed within the deadline and they continue to be an appreciated client.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - modular, with jQuery</li>
                          <li>CMS - WordPress</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://gdsgroup.com/" target="_blank" rel="noopener noreferrer">https://gdsgroup.com/</a></li>
                          <li><a href="https://jugo.io/" target="_blank" rel="noopener noreferrer">https://jugo.io/</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-consumeradditions.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-consumeradditions.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-consumeradditions.jpg" alt="Consumer Additions website preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">Consumer Additions</h2>
                        <p><a href="https://consumeradditions.carlsimmons.com/" target="_blank" rel="noopener noreferrer">This project</a> was developed whilst contracting for <a href="https://www.drivecreativestudio.co.uk/" target="_blank" rel="noopener noreferrer">Drive Creative Studio</a>. The design and animations brief for this was challenging, which made the project a lot of fun to develop. For the most part I managed to just use plain CSS transitions in order to get the best performance. Time to deliver (including UAT and making the site CMSable) was 10 working days.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - modular, with jQuery</li>
                          <li>CMS - WordPress</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://consumeradditions.carlsimmons.com/" target="_blank" rel="noopener noreferrer">https://consumeradditions.carlsimmons.com/</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-beachwise.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-beachwise.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-beachwise.jpg" alt="Beachwise website preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">Beachwise</h2>
                        <p><a href="https://www.beachwise.uk/" target="_blank" rel="noopener noreferrer">This project</a> was developed whilst contracting for <a href="https://www.proctors.co.uk/" target="_blank" rel="noopener noreferrer">Proctor + Stevenson</a>. This responsive interactive infographic had a lot of challenges and meant having to work closely with the design team to solve the complexities involved. However, it was one of the most fun projects I've worked on and was eventually delivered within the deadline of 13 working days.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - with jQuery</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://www.beachwise.uk/" target="_blank" rel="noopener noreferrer">https://www.beachwise.uk/</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-meteor.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-meteor.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-meteor.jpg" alt="Meteor app preview" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">Meteor App</h2>
                        <p><a href="https://play.google.com/store/apps/details?id=ie.meteor"target="_blank" rel="noopener noreferrer">This project</a> was developed whilst employed at <a href="https://mubaloo.com/">Mubaloo</a> during 2013. I developed the front-end whilst the client supplied a REST API. The application was developed as a single page app with Phonegap, which meant we were able to release it through the Apple App Store and Google Play Store. It's quite old but I like to show it because it was one of the first non-B2B web applications I developed.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                      <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with LESS</li>
                          <li>JS - with Backbone</li>
                          <li>Phonegap</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://play.google.com/store/apps/details?id=ie.meteor" target="_blank" rel="noopener noreferrer">Google Play Store</a></li>
                          <li><a href="https://itunes.apple.com/ie/app/mymeteor/id632036399?mt=8" target="_blank" rel="noopener noreferrer">Apple App Store</a></li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
                <Transition on="inview">
                  <hr />
                </Transition>
                <article className="portfolio__article">
                  <div className="portfolio__column">
                    <div className="portfolio__image">
                      <Transition on="inview" type="fadetoup">
                        <picture>
                          <source srcSet="/static/img/portfolio-others.webp" type="image/webp" />
                          <source srcSet="/static/img/portfolio-others.jpg" type="image/jpeg" /> 
                          <img src="/static/img/portfolio-others.jpg" alt="Other app previews" />
                        </picture>
                      </Transition>
                    </div>
                  </div>
                  <div className="portfolio__column">
                    <div className="portfolio__details">
                      <Transition on="inview" type="fadetoup">
                        <h2 className="portfolio__heading h3">Development Playground</h2>
                        <p>These are some little projects that I've worked on in my own time in the past, usually for fun, but also for learning which is why they use technologies they dont necessarily need. Some of them load slow due to using free hosting.</p>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                      <h3 className="portfolio__heading h3">Technical Sheet</h3>
                        <ul>
                          <li>HTML5 - semantic</li>
                          <li>CSS3 - pre-processed with SASS</li>
                          <li>JS - with jQuery</li>
                          <li>Angular</li>
                          <li>React</li>
                        </ul>
                      </Transition>
                      <Transition on="inview" type="fadetoup">
                        <h3 className="portfolio__heading h3">Resources</h3>
                        <ul>
                          <li><a href="https://monstersdb.com/" target="_blank">Monsters DB</a> - built with NextJS/API Routes</li>
                          <li><a href="https://gift-recommender.com/" target="_blank">Gift Recommender</a> - uses machine learning</li>
                          <li><a href="http://challengemyday.tempstage.website/" target="_blank">Challenge My Day</a> - built with React Native</li>
                          <li><a href="https://staticmanager.carlsimmons.com" target="_blank">Static Manager</a> - CMS built with Sails, Angular, and Socket.IO</li>
                        </ul>
                      </Transition>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </section>
          <section id="contact" className="section section--blue">
            <div className="section__inner">
              <div className={'contact'+(contactStore.loading ? ' loading' : '')+(contactStore.success ? ' success' : '')+(contactStore.errors.length > 0 ? ' error' : '')}>
                <Transition on="inview" type="fadescale">
                  <h1 className="section__heading">Contact</h1>
                </Transition>
                <Transition on="inview" type="fadescale">
                  <p className="contact__body">If you’re interested then email me at <a href="mailto:hello@carlsimmons.com">hello@carlsimmons.com</a> or fill in the form below.</p>
                </Transition>
                <form className="contact__form" onSubmit={this.handleContactSubmit}>
                  <Transition on="inview" type="fadescale">
                    <input type="text" placeholder="Name" required ref={(ref) => { this.name = ref }} onFocus={this.onFocus} onBlur={this.onBlur}/>
                  </Transition>
                  <Transition on="inview" type="fadescale">
                    <input type="email" placeholder="Email" required ref={(ref) => { this.email = ref }} onFocus={this.onFocus} onBlur={this.onBlur}/>
                  </Transition>
                  <Transition on="inview" type="fadescale">
                    <textarea placeholder="Message" required ref={(ref) => { this.message = ref }} onFocus={this.onFocus} onBlur={this.onBlur}/>
                  </Transition>
                  <Transition on="inview" type="fadescale">
                    <div className="contact__submission">
                      <button className="button" type="submit">
                        <span className="button__label button__label--error"><img src={crossImg} alt="cross" /></span>
                        <span className="button__label button__label--default">Send</span>
                        <span className="button__label button__label--success"><img src={tickImg} alt="tick" /></span>
                      </button>
                      <div className="contact__response">
                        <div>
                          {contactStore.errors.length > 0 ? 'Apologies, something went wrong. Please check the form and try again.' : ''}
                          {contactStore.success ? 'Thanks for your message, I\'ll respond as soon as I\'m able to.' : ''}
                        </div>
                      </div>
                    </div>
                  </Transition>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
)

export default App;