import React, { Component } from 'react';
import { observer } from 'mobx-react';
import helpers from '../helpers';
import config from '../config';
import gameStore from '../stores/GameStore';

let moveInterval;

const Monster = observer(
class Monster extends Component {
  constructor(props) {
    super(props);
    this.store = gameStore.monsters[props.index];
  }

  componentDidMount = () => {
    window.addEventListener('click', this.handleClick, false);

    moveInterval = setInterval(() => {
      this.move();
    }, config.monsterMoveIntervalLength);
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClick, false);
    clearInterval(moveInterval);
  }

  handleClick = (event) => {
    setTimeout(() => {
      this.isHitByPlayerWeaponRect(gameStore.player.weapon.rect);
    }, 50);
  }

  move = () => {
    if(typeof this.store.path !== 'undefined' && this.store.health > 0) {
      const nextPosition = this.store.path.shift();
      let position = this.store.position;

      // Mirror if moving left, unmirror if moving right
      if(nextPosition.x < position.x) {
        this.store.mirrored = true;
      } else if(nextPosition.x > position.x) {
        this.store.mirrored = false;
      }

      // Update position and push it onto the end again
      this.store.position = nextPosition;
      this.store.path.push(nextPosition);

      // Check if hits player
      if(helpers.objectsAreEqual(gameStore.player.position, nextPosition)) {
        gameStore.hitPlayer();
      }
    }
  }

  isHitByPlayerWeaponRect = (playerWeaponRect) => {
    if(!this.store.hit && this.store.health > 0 && gameStore.player.health > 0) {
      const monsterRect = helpers.getRectForNode(this);
      const overlap = helpers.calculateRectOverlap(playerWeaponRect, monsterRect);

      if(overlap > 10) {
        this.store.hit = true;
        this.store.health = this.store.health - gameStore.player.weapon.damage;

        const sound = new Audio('static/sound/hit_monster_3.mp3');
        sound.play();

        // Open related doors on death...
        if(this.store.health <= 0) {
          const doors = gameStore.getTilesBySprite('doors_small_leaf_closed');
          for(const door of doors) {
            if(this.store.sprite === door.openedBy) {
              door.sprite = 'doors_small_leaf_open';

              const sound = new Audio('static/sound/open_door.mp3');
              sound.play();
            }
          }
        }
      }

      setTimeout(() => {
        this.store.hit = false;
      }, 300);
    }
  }

  render() {
    let style = helpers.getOffsetForPosition(this.store.position);
    let classes = '';

    if(this.store.hit) {
      classes = classes + ' hit';
    }

    if(this.store.health <= 0) {
      classes = classes + ' dying';
    }

    if(this.store.mirrored) {
      classes = classes + ' mirrored';
    }

    style.zIndex = this.store.position.y;

    return (
      <div className={'game__entity game__monster sprite--'+this.store.sprite+classes} style={style}></div>
    )
  }
}
);

export default Monster;