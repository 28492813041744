import { observable, decorate } from 'mobx';

class TransitionStore {
	delayOffset = 0;
};

decorate(TransitionStore, {
	delayOffset: observable
});

const transitionStore = new TransitionStore();

export default transitionStore;