import { observable, decorate } from 'mobx';

class ContactStore {
	loading = false;
	success = false;
	errors = [];
};

decorate(ContactStore, {
	loading: observable,
	success: observable,
	errors: observable
});

const contactStore = new ContactStore();

export default contactStore;