import React, { Component } from 'react';
import { observer } from 'mobx-react';
import helpers from '../helpers';
import gameStore from '../stores/GameStore';

const PlayerWeapon = observer(
class PlayerWeapon extends Component {
  constructor(props) {
    super(props);
    this.store = gameStore.player.weapon;
  }

  componentDidMount = () => {
    window.addEventListener('click', this.handleClick, false);
    window.addEventListener('mousemove', this.handleMouseMove, false);
  }

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClick, false);
    window.removeEventListener('mousemove', this.handleMouseMove, false);
  }

  handleClick = (event) => {
    if(!this.store.hitting && gameStore.player.movable) {
      this.store.hitting = true;
      this.store.rect = helpers.getRectForNode(this);

      const sound = new Audio('static/sound/slash_3.mp3');
      sound.play();
 
      setTimeout(() => {
        this.store.hitting = false;
      }, 300);
    }
  }

  handleMouseMove = (event) => {
    const playerOffset = helpers.getOffsetForPosition(gameStore.player.position); // position is relative to game rect...
    const mouseOffset = {
      top: event.pageY - gameStore.rect.top - window.pageYOffset, // have to add in game rect to also make it relevant
      left: event.pageX - gameStore.rect.left
    };
    const angle = helpers.getAngleBetweenOffsets(playerOffset, mouseOffset);

    this.store.angle = angle;
    gameStore.player.mirrored = (this.store.angle > 180 && this.store.angle < 360);
  }

  render() {
    let holderClasses = 'game__player-weapon-holder';
    let weaponClasses = 'game__player-weapon sprite--'+this.store.sprite;
    let holderStyle = {transform: 'translate(-30%, -58%) rotate('+(gameStore.player.mirrored ? -(this.store.angle) : this.store.angle)+'deg)'};

    if(this.store.hitting) {
      weaponClasses = weaponClasses+' game__player-weapon--hitting';
    }

    return (
        <div className={holderClasses} style={holderStyle}>
          {gameStore.player.claiming ? '' : <div className={weaponClasses}></div>}
        </div>
    )
  }
}
);

export default PlayerWeapon;